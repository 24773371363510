import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { StorePageSizeDirectiveModule } from '@cdk/store-page-size';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsAbsoluteExpansionPanelComponent } from '@design-system/components/absolute-expansion-panel';
import { DsFilterModule } from '@design-system/components/filter';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { TranslateModule } from '@ngx-translate/core';
import { MatDayJsDateModule } from '@paldesk/shared-lib/utils/date-utils';
import { FileSizePipeModule } from '@utils/file-size-pipe';
import { Angulartics2Module } from 'angulartics2';

const sharedModules = [
  DsAbsoluteExpansionPanelComponent,
  Angulartics2Module,
  CommonModule,
  StorePageSizeDirectiveModule,
  DsSpacingModule,
  FlexLayoutModule,
  FormsModule,
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatDayJsDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  DsPlaceholderModule,
  ReactiveFormsModule,
  PdLetDirectiveModule,
  DsSnackbarModule,
  DsTableLoadingModule,
  DsTableModule,
  TranslateModule,
  FileSizePipeModule,
  MatMenuModule,
  MatTooltipModule,
  MatChipsModule,
  DsFilterModule,
  DsLoadingModule,
];

@NgModule({
  imports: sharedModules,
  exports: sharedModules,
})
export class SharedModule {}
