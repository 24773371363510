import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { UserService } from '@features/auth';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';
import { saveAs } from 'file-saver';
import { Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, finalize } from 'rxjs/operators';
import {
  ClaimService,
  ColumnName,
  GetClaimdeskResult,
  PositionStatus,
  SortType,
} from '../../shared/generated';

interface Filter {
  claim_type: FormControl<string[] | null>;
  sap_number: FormControl<string | null>;
  supplier_cs_number: FormControl<string | null>;
  start_date: FormControl<string | null>;
  end_date: FormControl<string | null>;
  product_line: FormControl<string[] | null>;
  serial_number: FormControl<string | null>;
}

@Component({
  selector: 'cos-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.scss'],
})
export class ClaimListComponent
  implements OnDestroy, AfterViewInit, AfterViewChecked
{
  objectKeys = Object.keys;
  positionStatus = PositionStatus;
  filter: FormGroup<Filter>;
  displayedColumns = [
    ColumnName.SapNumber,
    ColumnName.SupplierCsNumber,
    'statuses',
    ColumnName.CreationDate,
    ColumnName.TotalRequested,
    ColumnName.TotalAccepted,
    ColumnName.ProductLine,
  ];
  cosSessionKeyPrefix: string;
  claims$: Observable<GetClaimdeskResult | undefined>;
  paginator: MatPaginator;
  sort: MatSort;
  columnName = ColumnName;
  sortActive: ColumnName;
  sortDirection: SortDirection;
  loadingError = false;
  exportRequestPending = false;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (mp && !this.paginator) {
      this.paginator = mp;
      this.paginator.pageIndex = parseInt(
        this.getSessionStorageByKey('pageIndex') || '0',
        10,
      );
    }
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (sort && !this.sort) this.sort = sort;
  }

  private readonly destroy$ = new Subject<void>();

  constructor(
    private claimService: ClaimService,
    private snackbar: DsSnackbar,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private userService: UserService,
  ) {
    this.cosSessionKeyPrefix = `'paldeskCos'-${this.userService.userContext.username}`;
    this.filter = this.fb.group({
      claim_type: new FormControl(
        this.getSessionStorageByKey('claimType')?.split(',') || [],
      ),
      sap_number: new FormControl(this.getSessionStorageByKey('sapNumber')),
      supplier_cs_number: [this.getSessionStorageByKey('supplierCsNumber')],
      start_date: [this.getSessionStorageByKey('startDate')],
      end_date: [this.getSessionStorageByKey('endDate')],
      product_line: [
        this.getSessionStorageByKey('productLine')?.split(',') || [],
      ],
      serial_number: [this.getSessionStorageByKey('serialNumber')],
    });

    this.filter.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.applyFilter());

    this.sortDirection =
      (this.getSessionStorageByKey('sortDirection') as SortDirection) || 'desc';
    this.sortActive =
      (this.getSessionStorageByKey('sortActive') as ColumnName) ||
      ColumnName.CreationDate;
  }

  ngAfterViewInit() {
    this.getClaims();
  }

  // fix for the translated values in <mat-select>, https://github.com/angular/components/issues/14793
  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  sortChange(sort: Sort) {
    if (this.paginator) this.paginator.pageIndex = 0;
    this.sortDirection = sort.direction;
    this.sortActive = sort.active as ColumnName;
    this.setSessionStorageByKey('sortDirection', this.sortDirection);
    this.setSessionStorageByKey('sortActive', this.sortActive);
    this.getClaims();
  }

  getClaims() {
    this.setSessionStorageByKey('pageIndex', this.paginator?.pageIndex);
    this.claims$ = this.claimService
      .claimOnSuppliersClaimdesk(
        this.paginator?.pageIndex,
        this.paginator?.pageSize,
        this.filter.controls.sap_number.value?.trim(),
        this.filter.controls.supplier_cs_number.value?.trim(),
        this.filter.controls.product_line.value?.join(','),

        this.filter.controls.start_date.value
          ? DateUtils.toISODateStringLocal(
              new Date(this.filter.controls.start_date.value),
            )
          : undefined,
        this.filter.controls.end_date.value
          ? DateUtils.toISODateStringLocal(
              new Date(this.filter.controls.end_date.value),
            )
          : undefined,
        this.filter.controls.claim_type.value?.join(','),
        this.filter.controls.serial_number.value?.trim(),
        this.sortActive,
        this.sortDirection === 'asc' ? SortType.Asc : SortType.Desc,
      )
      .pipe(
        catchError((err) => {
          this.loadingError = true;
          this.handleError(err);
          return of(undefined);
        }),
      );
  }

  /**
   * Download claims as CSV files export
   */
  exportClaims() {
    this.exportRequestPending = true;
    this.claimService
      .claimOnSuppliersExportClaims(
        this.filter.controls.sap_number.value?.trim(),
        this.filter.controls.supplier_cs_number.value?.trim(),
        this.filter.controls.product_line.value?.join(','),

        this.filter.controls.start_date.value
          ? DateUtils.toISODateStringLocal(
              new Date(this.filter.controls.start_date.value),
            )
          : undefined,
        this.filter.controls.end_date.value
          ? DateUtils.toISODateStringLocal(
              new Date(this.filter.controls.end_date.value),
            )
          : undefined,
        this.filter.controls.claim_type.value?.join(','),
        this.filter.controls.serial_number.value?.trim(),
        this.sortActive,
        this.sortDirection === 'asc' ? SortType.Asc : SortType.Desc,
      )
      .pipe(
        finalize(() => (this.exportRequestPending = false)),
        catchError((err) => {
          this.handleError(err);
          return of(undefined);
        }),
      )
      .subscribe((data) => {
        if (data) {
          const date = new Date();
          const dateWritten =
            date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear();
          const blob = new Blob([data], { type: 'text/csv' });
          saveAs(blob, 'exportedClaimsOnSupplier_' + dateWritten + '.csv');
        }
      });
  }

  handleError(err) {
    const message =
      err.error && err.error.message ? err.error.message : 'Loading error';
    this.snackbar.queue(message, {
      type: DsSnackbarType.Error,
    });
  }

  applyFilter() {
    if (this.paginator) this.paginator.pageIndex = 0;

    this.setSessionStorageByKey(
      'claimType',
      this.filter.controls.claim_type.value || '',
    );
    this.setSessionStorageByKey(
      'sapNumber',
      this.filter.controls.sap_number.value?.trim() || '',
    );
    this.setSessionStorageByKey(
      'supplierCsNumber',
      this.filter.controls.supplier_cs_number.value?.trim(),
    );

    this.setSessionStorageByKey(
      'startDate',
      this.filter.controls.start_date.value
        ? DateUtils.toISODateStringLocal(
            new Date(this.filter.controls.start_date.value),
          )
        : '',
    );

    this.setSessionStorageByKey(
      'endDate',
      this.filter.controls.start_date.value
        ? DateUtils.toISODateStringLocal(
            new Date(this.filter.controls.end_date.value || ''),
          )
        : '',
    );
    this.setSessionStorageByKey(
      'productLine',
      this.filter.controls.product_line.value || '',
    );

    this.getClaims();
    this.filter.markAsPristine();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private setSessionStorageByKey(key: string, value): void {
    if (value) {
      sessionStorage.setItem(`${this.cosSessionKeyPrefix}-${key}`, value);
    } else {
      sessionStorage.removeItem(`${this.cosSessionKeyPrefix}-${key}`);
    }
  }

  private getSessionStorageByKey = (key: string): string | null =>
    sessionStorage.getItem(`${this.cosSessionKeyPrefix}-${key}`) || null;
}
